import React from "react";
import NavBar from "./NavBar";

const Layout = () =>{
    return(
        <>
        <NavBar/>
        </>
    )
};

export default Layout;


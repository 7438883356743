import React from 'react';
import Value from '../images/photo.jpg';

const Header = () => {
  return (<>
    <div className='text-center'>
      <img src={Value} className="rounded-circle" style={{ height: '20vh', width: 'auto' }} alt="Yenealem"/>
      
    </div>
    </>
  );
};

export default Header;

import React from 'react';

const Contact = () => {
    return (
      <div>
        <hr style={{color: 'red', fontSize:'5'}}/>
        <b><i>Email:</i></b> yenealemn@gmail.com<br/>
        <b><i>Phone:</i></b> +1-641-819-8333
        </div>
    );
  };
  
  export default Contact;
  
import React from 'react';

const Blog = () => {
    return (
      <div><br/>
        <h5>My job searching and cloud computing 😊</h5>
        <i><p>My journey in the realm of job searching and the ever-evolving landscape of cloud computing technology has been quite a fascinating one. As computer technology continues to spread its influence across businesses worldwide, the Information and Communication Technology (ICT) industry demands a firm grasp of cloud services and their role in supporting application and web development projects. The realm of cloud services is vast and continually expanding, but it has also evolved to become more agile, cost-effective, and versatile, making it an attractive investment for individuals and organizations alike.</p>
        <p>Whether you identify as a programmer, a data scientist, an information security expert, a product owner, or a project manager, knowledge of cloud technology is increasingly vital in any ICT-related role. My own journey as an experienced programmer, with over 15 years in various IT positions, included a decade of working with on-premises servers. Back then, the process was relatively straightforward: using applications to transfer development files between our local machines and corporate servers. Cloud services were not yet part of our project landscape, and integrated version control and Continuous Integration/Continuous Deployment (CICD) tools were not fully integrated.</p>
        <p>However, when I embarked on a job search for a developer position in the United States, I quickly realized the significance of cloud technology. I managed to pass two rounds of coding challenges and joined Amazon, working alongside seasoned professionals who dedicated their time to finding solutions for diverse software development tasks. While it was a valuable experience, my lack of cloud expertise limited my stay to just two months.</p>
        <p>Subsequently, after several months of job searching for a programmer position, I returned to Sam’s Club. I stayed there until the project's completion, but I faced a significant challenge when it came to executing tasks using cloud services, particularly with the use of Azure and GCP. Adapting to the cloud environment consumed a significant portion of my sprint time. Coding and testing tasks were manageable, but the hurdle lay in deploying the completed tasks to the cloud.</p>
        <p>Following the project's completion and not receiving a contract extension, I decided to focus on cloud computing for the next phase of my career. I invested in online tutorials, printed books, and YouTube tutorials to address specific cloud-related tasks. I embarked on a journey to acquaint myself with the intricacies of cloud computing technologies and platforms, and it has been a rewarding experience.</p>
        <p>Taking cloud computing exams and certifications has been a significant part of my journey. At present, I hold two certifications: AWS Developer Associate and Google ACE Associate. However, I still feel the need to learn more, exercise my skills, and further develop my expertise.</p>
        <p>Thank you for taking the time to read about my journey, and I look forward to what lies ahead in the world of cloud computing 😊</p>
        </i>
      </div>
    );
  };
  
  export default Blog;
  